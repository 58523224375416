import NavBar from "./NavBar";
import React from "react";
import {HeroSection} from "./HeroSection";

export default function AnotherPage() {
    return (
        <div className="bg-white">
            <div className="relative overflow-hidden">
                <NavBar/>
                <main>
                    <HeroSection/>
                </main>
            </div>
        </div>
    )
}