// @flow
import * as React from 'react';
import {CloudIcon, DeviceMobileIcon, LinkIcon, ServerIcon, SparklesIcon, TerminalIcon} from "@heroicons/react/outline";

type Props = {
    name: string,
    className: string
};

export const HeroIconByName = (props: Props) => {
    const getIcon = () => {
        switch (props.name) {
            case "CloudIcon":
                return <CloudIcon className={props.className} aria-hidden="true"/>;
            case "DeviceMobileIcon":
                return <DeviceMobileIcon className={props.className} aria-hidden="true"/>;
            case "LinkIcon":
                return <LinkIcon className={props.className} aria-hidden="true"/>;
            case "ServerIcon":
                return <ServerIcon className={props.className} aria-hidden="true"/>;
            case "SparklesIcon":
                return <SparklesIcon className={props.className} aria-hidden="true"/>;
            case "TerminalIcon":
                return <TerminalIcon className={props.className} aria-hidden="true"/>;
            default:
                return <span className={props.className} aria-hidden="true">?</span>
        }
    }

    return (getIcon());
};