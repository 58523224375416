import React, {useContext, useEffect, useMemo, useState} from 'react'
import Footer from "./Footer";
import NavBar from "./NavBar";
import {createSearchParams, useNavigate, useSearchParams} from "react-router-dom";
import {LeadService} from "./service/LeadService";
import * as Yup from 'yup';
import {
    Formik,
    FormikHelpers,
    FormikProps,
    Form,
    Field,
    FieldProps,
} from 'formik';
import {SimpleModal} from "./modal/SimpleModal";
import {CheckIcon, ExclamationCircleIcon} from "@heroicons/react/outline";
import {EnvironmentContext} from "./common/EnvironmentProvider";


const schema = Yup.object().shape({
    email: Yup.string().required("Email is required").email(),
    company: Yup.string().required("Company Name is required"),
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    referral: Yup.string().required("This field is required"),
    idea: Yup.string().required("Please share your idea")
});


export interface ContactForm {
    email: string;
    phone: string;
    company: string;
    firstName: string;
    lastName: string;
    idea: string;
    budget: string;
    referral: string;
    submitted: string | undefined;
}

export default function ContactUsPage() {
    const env = useContext(EnvironmentContext);
    const leadService = useMemo(() => {
        console.log("Building Contact Service")
        return new LeadService(env.api);
    }, [env])


    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = React.useState(false);
    const [submitted, setSubmitted] = React.useState(false);
    const [failed, setFailed] = React.useState(false);
    const initialValues: ContactForm = {
        email: searchParams.get("email") || '',
        phone: '',
        company: '',
        firstName: '',
        lastName: '',
        idea: '',
        budget: '',
        referral: '',
        submitted: undefined
    };

    const submitForm = (event: any) => {
        console.log("Submitting form!", event)
        setLoading(true)
        console.log("A", event)
        env.analytics.logEvent('ContactUs:Submit')
        leadService.postContactForm(event, () => {
            env.analytics.logEvent('ContactUs:Success')
            setLoading(false);
            setSubmitted(true);
        }, () => {
            env.analytics.logEvent('ContactUs:Failure')
            setLoading(false);
            setFailed(true);
        });
    };

    return (
        <div className="bg-white">
            <NavBar/>
            <main>
                {/* Header */}
                <div className="py-24 bg-gray-50 sm:py-32">
                    <div className="max-w-md mx-auto pl-4 pr-8 sm:max-w-lg sm:px-6 lg:max-w-7xl lg:px-8">
                        <h1 className="text-4xl leading-10 font-extrabold tracking-tight text-gray-900 text-center sm:text-5xl sm:leading-none lg:text-6xl">
                            Get in touch
                        </h1>
                        <p className="mt-6 max-w-3xl mx-auto text-xl leading-normal text-gray-500 text-center">
                            There are no limitations with today's technology. Let's build something special today.
                        </p>
                    </div>
                </div>

                {/* Contact Section */}
                <div className="relative bg-white">
                    <div className="lg:absolute lg:inset-0">
                        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                            <img
                                className="h-56 w-full object-cover lg:absolute lg:h-full"
                                src="https://images.unsplash.com/photo-1556761175-4b46a572b786?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1567&q=80"
                                alt=""
                            />
                        </div>
                    </div>
                    <div id="contact-form-header"
                         className="relative py-16 px-4 sm:py-24 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:py-32 lg:grid lg:grid-cols-2">
                        <div className="lg:pr-8">
                            <div className="max-w-md mx-auto sm:max-w-lg lg:mx-0">
                                <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">Let's work
                                    together</h2>
                                <p className="mt-4 text-lg text-gray-500 sm:mt-3">
                                    We’d love to hear from you! Send us a message using the form below and we'll get
                                    back to you as soon as possible.
                                </p>
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={schema}
                                    onSubmit={submitForm}>
                                    {({
                                          handleSubmit,
                                          handleChange,
                                          handleBlur,
                                          values,
                                          touched,
                                          isValid,
                                          dirty,
                                          errors,
                                      }) => (
                                        <Form action="#" method="POST" noValidate id="contact-form"
                                              className="mt-9 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                                            <div>
                                                <label htmlFor="first-name"
                                                       className="block text-sm font-medium text-gray-700">
                                                    First name
                                                </label>
                                                <div className="mt-1">
                                                    <Field
                                                        type="text"
                                                        name="firstName"
                                                        id="firstName"
                                                        autoComplete="given-name"
                                                        className="block w-full shadow-sm sm:text-sm focus:ring-gold-500 focus:border-gold-500 border-gray-300 rounded-md"/>
                                                    {touched.firstName && errors.firstName &&
                                                        <p className="mt-2 text-sm text-red-600" id="firstName-error">
                                                            {errors.firstName}
                                                        </p>}
                                                </div>
                                            </div>
                                            <div>
                                                <label htmlFor="first-name"
                                                       className="block text-sm font-medium text-gray-700">
                                                    Last name
                                                </label>
                                                <div className="mt-1">
                                                    <Field type="text"
                                                           name="lastName"
                                                           id="lasName"
                                                           autoComplete="family-name"
                                                           className="block w-full shadow-sm sm:text-sm focus:ring-gold-500 focus:border-gold-500 border-gray-300 rounded-md"/>
                                                    {touched.lastName && errors.lastName &&
                                                        <p className="mt-2 text-sm text-red-600" id="lastName-error">
                                                            {errors.lastName}
                                                        </p>}
                                                </div>
                                            </div>
                                            <div className="sm:col-span-2">
                                                <label htmlFor="email"
                                                       className="block text-sm font-medium text-gray-700">
                                                    Email
                                                </label>
                                                <div className="mt-1">
                                                    <Field
                                                        id="email"
                                                        name="email"
                                                        type="email"
                                                        autoComplete="email"
                                                        className="block w-full shadow-sm sm:text-sm focus:ring-gold-500 focus:border-gold-500 border-gray-300 rounded-md"
                                                    />
                                                    {touched.email && errors.email &&
                                                        <p className="mt-2 text-sm text-red-600" id="email-error">
                                                            {errors.email}
                                                        </p>}
                                                </div>
                                            </div>
                                            <div className="sm:col-span-2">
                                                <label htmlFor="company"
                                                       className="block text-sm font-medium text-gray-700">
                                                    Company
                                                </label>
                                                <div className="mt-1">
                                                    <Field
                                                        type="text"
                                                        name="company"
                                                        id="company"
                                                        autoComplete="organization"
                                                        className="block w-full shadow-sm sm:text-sm focus:ring-gold-500 focus:border-gold-500 border-gray-300 rounded-md"
                                                    />
                                                    {touched.company && errors.company &&
                                                        <p className="mt-2 text-sm text-red-600" id="company-error">
                                                            {errors.company}
                                                        </p>}
                                                </div>
                                            </div>
                                            <div className="sm:col-span-2">
                                                <div className="flex justify-between">
                                                    <label htmlFor="phone"
                                                           className="block text-sm font-medium text-gray-700">
                                                        Phone
                                                    </label>
                                                    <span id="phone-description" className="text-sm text-gray-500">
                    Optional
                  </span>
                                                </div>
                                                <div className="mt-1">
                                                    <Field
                                                        type="text"
                                                        name="phone"
                                                        id="phone"
                                                        autoComplete="tel"
                                                        aria-describedby="phone-description"
                                                        className="block w-full shadow-sm sm:text-sm focus:ring-gold-500 focus:border-gold-500 border-gray-300 rounded-md"
                                                    />
                                                </div>
                                            </div>
                                            <div className="sm:col-span-2">
                                                <div className="flex justify-between">
                                                    <label htmlFor="how-can-we-help"
                                                           className="block text-sm font-medium text-gray-700">
                                                        How can we help you?
                                                    </label>
                                                    <span id="how-can-we-help-description"
                                                          className="text-sm text-gray-500">
                    Max. 500 characters
                  </span>
                                                </div>
                                                <div className="mt-1">
                                                    <Field id="how-can-we-help"
                                                           name="idea"
                                                           aria-describedby="how-can-we-help-description"
                                                           rows={4}
                                                           className="block w-full shadow-sm sm:text-sm focus:ring-gold-500 focus:border-gold-500 border border-gray-300 rounded-md"
                                                           defaultValue={''} component="textarea">
                                                    </Field>
                                                    {touched.idea && errors.idea &&
                                                        <p className="mt-2 text-sm text-red-600" id="idea-error">
                                                            {errors.idea}
                                                        </p>}
                                                </div>
                                            </div>
                                            <fieldset className="sm:col-span-2">
                                                <legend className="block text-sm font-medium text-gray-700">Expected
                                                    budget
                                                </legend>
                                                <div className="mt-4 grid grid-cols-1 gap-y-4">
                                                    <div className="flex items-center">
                                                        <Field
                                                            id="budget-under-10k"
                                                            name="budget"
                                                            value="under_25k"
                                                            type="radio"
                                                            className="focus:ring-gold-500 h-4 w-4 text-gold-600 border-gray-300"
                                                        />
                                                        <label htmlFor="budget-under-10k" className="ml-3">
                                                            <span
                                                                className="block text-sm text-gray-700">Less than $10K</span>
                                                        </label>
                                                    </div>
                                                    <div className="flex items-center">
                                                        <Field
                                                            id="budget-10k-25k"
                                                            name="budget"
                                                            value="10k-25k"
                                                            type="radio"
                                                            className="focus:ring-gold-500 h-4 w-4 text-gold-600 border-gray-300"
                                                        />
                                                        <label htmlFor="budget-10k-25k" className="ml-3">
                                                            <span
                                                                className="block text-sm text-gray-700">$10K – $25K</span>
                                                        </label>
                                                    </div>
                                                    <div className="flex items-center">
                                                        <Field
                                                            id="budget-25k-50k"
                                                            name="budget"
                                                            value="25k-50k"
                                                            type="radio"
                                                            className="focus:ring-gold-500 h-4 w-4 text-gold-600 border-gray-300"
                                                        />
                                                        <label htmlFor="budget-25k-50k" className="ml-3">
                                                            <span
                                                                className="block text-sm text-gray-700">$25K – $50K</span>
                                                        </label>
                                                    </div>
                                                    <div className="flex items-center">
                                                        <Field
                                                            id="budget-50k-100k"
                                                            name="budget"
                                                            value="50k-100k"
                                                            type="radio"
                                                            className="focus:ring-gold-500 h-4 w-4 text-gold-600 border-gray-300"
                                                        />
                                                        <label htmlFor="budget-50k-100k" className="ml-3">
                                                            <span
                                                                className="block text-sm text-gray-700">$50k - $100K</span>
                                                        </label>
                                                    </div>
                                                    <div className="flex items-center">
                                                        <Field
                                                            id="budget-over-100k"
                                                            name="budget"
                                                            value="over_100k"
                                                            type="radio"
                                                            className="focus:ring-gold-500 h-4 w-4 text-gold-600 border-gray-300"
                                                        />
                                                        <label htmlFor="budget-over-100k" className="ml-3">
                                                            <span className="block text-sm text-gray-700">$100K+</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </fieldset>
                                            <div className="sm:col-span-2">
                                                <label htmlFor="referral"
                                                       className="block text-sm font-medium text-gray-700">
                                                    How did you hear about us?
                                                </label>
                                                <div className="mt-1">
                                                    <Field
                                                        type="text"
                                                        name="referral"
                                                        id="referral"
                                                        className="shadow-sm focus:ring-gold-500 focus:border-gold-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                    />
                                                    {touched.referral && errors.referral &&
                                                        <p className="mt-2 text-sm text-red-600" id="referral-error">
                                                            {errors.referral}
                                                        </p>}
                                                </div>
                                            </div>
                                            <div className="text-right sm:col-span-2">
                                                <button
                                                    type="submit"
                                                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gold-600 hover:bg-gold-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gold-500 disabled:opacity-50"
                                                    disabled={(!isValid || !dirty) && !loading && !submitted}
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </Form>)}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    {submitted &&
                        <SimpleModal
                            icon={
                                <div
                                    className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                                    <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true"/>
                                </div>}
                            title={"Thank you!"}
                            message={"We've received your request and will reach out to you as soon as possible."}
                            buttonText={"Return to Home"}
                            onClick={() => {
                                return () => {
                                    env.analytics.logEvent('ContactUs:Success:Home')
                                    navigate({pathname: "/#nav-logo"});
                                }
                            }}/>
                    }
                </div>
                <div>
                    {failed &&
                        <SimpleModal
                            icon={
                                <div
                                    className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                                    <ExclamationCircleIcon className="h-6 w-6 text-red-600" aria-hidden="true"/>
                                </div>
                            }
                            title={"Whoops!"}
                            message={"Something went wrong."}
                            buttonText={"Refresh & Try Again"}
                            onClick={() => {
                                return () => {
                                    env.analytics.logEvent('ContactUs:Failure:TryAgain')
                                    if (env.prod) {
                                        window.location.reload()
                                    } else {
                                        console.log("Behavior is changed for dev mode.");
                                        setFailed(false)
                                    }
                                }
                            }}/>
                    }
                </div>
            </main>
            <Footer/>
        </div>
    )
}
