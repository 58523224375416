import {Popover, Transition} from "@headlessui/react";
import whiteLogo from "./assets/puretech-logo-white.png";
import blackLogo from "./assets/puretech-logo-black.png";
import {MenuIcon, XIcon} from "@heroicons/react/outline";
import React, {Fragment} from "react";
import {Link} from "react-router-dom";
import {ChevronDownIcon} from "@heroicons/react/solid";
import classNames from "classnames";


const navigation = [
    {
        name: 'Product', href: '#', visible: false, subLinks: [
            {name: 'Product', href: '#'}
        ]
    },
    {name: 'Features', href: '#', visible: false, subLinks: []},
    {name: 'Marketplace', href: '#', visible: false, subLinks: []},
    {name: 'Company', href: '#', visible: false, subLinks: []},
]

export default function NavBar() {
    const open = false;
    return (
        <div className="bg-gray-900 pt-6 pb-2">
            <nav
                className="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6"
                aria-label="Global"
            >
                <div className="flex items-center flex-1">
                    <div className="flex items-center justify-between w-full md:w-auto">
                        <Link to="/">
                            <span className="sr-only">PureTech</span>
                            <img id="nav-logo" src={whiteLogo} className="h-8 w-auto sm:h-10" alt=""/>
                        </Link>
                    </div>
                    <div className="hidden space-x-8 md:flex md:ml-10">

                        <Popover.Group as="nav" className="hidden space-x-10 md:flex">
                            <Popover className="relative">
                                {navigation.filter(item => item.visible).map((item) => (

                                    <Popover.Button
                                        className={classNames(
                                            open ? 'text-gray-900' : 'text-white',
                                            'group inline-flex items-center rounded-md  text-base font-medium hover:text-gray-100 focus:outline-none focus:ring-2 focus:ring-gold-500 focus:ring-offset-2'
                                        )}
                                    >
                                        <span>{item.name}</span>
                                        {item.subLinks.length > 0 && <ChevronDownIcon
                                            className={classNames(
                                                open ? 'text-gray-600' : 'text-gray-400',
                                                'ml-2 h-5 w-5 group-hover:text-gray-500'
                                            )}
                                            aria-hidden="true"
                                        />}
                                    </Popover.Button>
                                ))}
                            </Popover>
                        </Popover.Group>
                    </div>
                </div>
            </nav>
        </div>
    );
}
