import {BaseApi} from "../apis/common/BaseApi";
import {AxiosApi} from "../apis/impl/AxiosApi";
import {FirebaseApi} from "../apis/impl/FirebaseApi";
import React from "react";
import {AnalyticsService} from "../service/AnalyticsService";


interface EnvironmentContextType {
    get prod(): Boolean

    get api(): BaseApi

    get analytics(): AnalyticsService
}

export const EnvironmentContext = React.createContext<EnvironmentContextType>({} as EnvironmentContextType);

export default class EnvironmentProvider implements EnvironmentContextType {
    private readonly leadSource: string | null;
    private readonly leadSourceList: string | null;
    private readonly _prod: boolean;
    readonly analytics: AnalyticsService;
    private _api: BaseApi | undefined;

    constructor(analytics: AnalyticsService) {
        this._prod = `${process.env.REACT_APP_PROD}` === 'true';
        this.leadSource = localStorage.getItem("ls");
        this.leadSourceList = localStorage.getItem("lsl");
        this.analytics = analytics;
        if (this.leadSource) {
            this.analytics.setProperty("ls", this.leadSource)
        }
        console.log("Initializing Environment!")
    }

    private buildApiClient(): BaseApi {
        if (process.env.REACT_APP_API === 'AXIOS') {
            console.log("Building Axios API Client")
            return new AxiosApi({
                platform: 'web',
                version: `${process.env.REACT_APP_VERSION}`,
                baseUrl: `${process.env.REACT_APP_BASE_API}`,
                apiKey: `${process.env.REACT_APP_API_KEY}`,
                token: undefined,
                source: this.leadSource,
                sources: this.leadSourceList
            });
        } else if (process.env.REACT_APP_API === 'FIREBASE') {
            console.log("Building Firebase API Client")
            return new FirebaseApi();
        } else {
            throw new Error(`Unable to build API Client '${process.env.REACT_APP_API}'`)
        }
    }


    get prod(): boolean {
        return this._prod;
    }

    get api(): BaseApi {
        if (!this._api) {
            this._api = this.buildApiClient();
        }
        return this._api;
    }

}
