import {logEvent, Analytics, setUserProperties, setCurrentScreen} from "firebase/analytics";

export class AnalyticsService {
    private readonly analytics: Analytics | null = null

    constructor(analytics: Analytics | null) {
        this.analytics = analytics
    }

    logEvent = (eventName: string) => {
        if (this.analytics) {
            logEvent(this.analytics, eventName)
        }
    }

    setProperty = (name: string, value: string) => {
        if (this.analytics) {
            setUserProperties(this.analytics, {
                name: value
            })
        }
    }

    setCurrentScreen = (screenName: string) => {
        if (this.analytics) {
            setCurrentScreen(this.analytics, screenName)
        }
    }
}
