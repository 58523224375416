// @flow
import * as React from 'react';
import {useFirebaseConfig} from "./hooks/useFirebaseConfig";
import heroImage from "./assets/puretech-hero-graphic.png"
import {useContext, useState} from "react";
import {createSearchParams, useNavigate} from "react-router-dom";
import {EnvironmentContext} from "./common/EnvironmentProvider";

type Props = {};

export function HeroSection(props: Props) {
    const env = useContext(EnvironmentContext);
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const {
        heroSection_HeaderLine1,
        heroSection_HeaderLine2,
        heroSection_HeaderSubtitle,
        heroSection_ImageUrl,
    } = useFirebaseConfig();

    const getImageUrl = () => {
        let imageUrl = heroImage
        if (heroSection_ImageUrl) {
            const firebaseOverride = heroSection_ImageUrl.toString()
            if (firebaseOverride.length > 0) {
                imageUrl = firebaseOverride
            }
        }
        return imageUrl;
    }

    const submitContactForm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        env.analytics.logEvent('Hero:ContactUs')
        navigate({
            pathname: "contact",
            search: `?${createSearchParams({
                email: email
            })}#contact-form-header`
        });
    }

    return (
        <div className="pt-10 bg-gray-900 sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden">
            <div className="mx-auto max-w-7xl lg:px-8">
                <div className="lg:grid lg:grid-cols-2 lg:gap-8">
                    <div
                        className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
                        <div className="lg:py-24">
                            <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                                <span className="block">{heroSection_HeaderLine1}</span>
                                <span
                                    className="pb-3 block bg-clip-text text-transparent bg-gradient-to-r from-amber-200 to-gold-400 sm:pb-5">
                        {heroSection_HeaderLine2}
                      </span>
                            </h1>
                            <p className="text-base text-gray-300 sm:text-xl lg:text-lg xl:text-xl">
                                {heroSection_HeaderSubtitle}
                            </p>
                            <div className="mt-10 sm:mt-12">
                                <form className="sm:max-w-xl sm:mx-auto lg:mx-0" onSubmit={submitContactForm}>
                                    <div className="sm:flex">
                                        <div className="min-w-0 flex-1">
                                            <label htmlFor="email" className="sr-only">
                                                Email address
                                            </label>
                                            <input
                                                id="email"
                                                type="email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                placeholder="Enter your email"
                                                autoComplete="off"
                                                className="block w-full px-4 py-3 rounded-md border-0 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gold-400 focus:ring-offset-gray-900"
                                            />
                                        </div>
                                        <div className="mt-3 sm:mt-0 sm:ml-3">
                                            <button
                                                type="submit"
                                                className="block w-full py-3 px-4 rounded-md shadow bg-gradient-to-r from-amber-500 to-gold-600 text-white font-medium hover:from-amber-600 hover:to-gold-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gold-400 focus:ring-offset-gray-900"
                                            >
                                                Contact Us
                                            </button>
                                        </div>
                                    </div>
                                    {/* <p className="mt-3 text-sm text-gray-300 sm:mt-4">
                                        Start your free 14-day trial, no credit card necessary. By providing
                                        your email, you agree to
                                        our{' '}
                                        <a href="#" className="font-medium text-white">
                                            terms of service
                                        </a>
                                        .
                                    </p>*/}
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="mt-12 lg:m-0 lg:relative">
                        <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0">
                            {/* Illustration taken from Lucid Illustrations: https://lucid.pixsellz.io/ */}
                            <img
                                className="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                                src={getImageUrl()}
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};