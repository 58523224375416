import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import {App} from "./App";

const existingLeadSourceList = localStorage.getItem("lsl") || '';
const leadSources = existingLeadSourceList.split(",")
const newLs = new URLSearchParams(window.location.search).get('ls');
if (newLs != null) {
    localStorage.setItem("ls", newLs)
}

ReactDOM.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
