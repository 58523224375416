import React from 'react'
import NavBar from "../../NavBar";
import Footer from "../../Footer";
import {useCookies} from "react-cookie";

type Props = {
    service: string
};


export default function LawmaticsAccountConnectionFailurePage(props: Props) {
    const [cookies, setCookie, removeCookie] = useCookies(['puretech-auth-retry-link'])
    const leftButtonText = () => {
        if (cookies["puretech-auth-retry-link"]) {
            return "Retry"
        } else {
            return "Home"
        }
    }
    const leftButtonHref = () => {
        if (cookies["puretech-auth-retry-link"]) {
            return cookies["puretech-auth-retry-link"]
        } else {
            return "/"
        }
    }

    return (
        <>
            <NavBar/>
            <main className="place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
                <div className="text-center">
                    <p className="text-base font-semibold text-gold-600">oops!</p>
                    <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Connection
                        Failed</h1>
                    <p className="mt-6 text-base leading-7 text-gray-600">Sorry, we couldn’t connect our app to
                        your {props.service} account.</p>
                    <div className="mt-10 flex items-center justify-center gap-x-6">
                        <a
                            href={leftButtonHref()}
                            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gold-600 hover:bg-gold-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gold-500 disabled:opacity-50"
                        >
                            {leftButtonText()}
                        </a>
                        <a href={"mailto:info@puretech.dev?subject=Help Connecting " + props.service + " Account"}
                           className="text-sm font-semibold text-gray-900">
                            Contact support <span aria-hidden="true">&rarr;</span>
                        </a>
                    </div>
                </div>
            </main>
            <Footer/>
        </>
    )
}
