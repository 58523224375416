import React from 'react'
import NavBar from "../../NavBar";
import Footer from "../../Footer";
import {ReactComponent as LogoSvg} from '../../assets/lawmatics-logo.svg';

type Props = {
    service: string
    serviceReturnPage: string
};


export default function LawmaticsAccountConnectedSuccessPage(props: Props) {
    return (
        <>
            <NavBar/>
            <main className="place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
                <div className="justify-center flex mb-10">
                    <LogoSvg className="max-h-36"/>
                </div>
                <div className="text-center">
                    <h1 className="mt-6 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Thank you!</h1>
                    <p className="mt-6 text-base  text-gray-600">Your {props.service} account has been connected.</p>
                    <p className="mt-2 text-base font-semibold leading-7 text-gray-600">We are grateful for the
                        opportunity to serve your business. Please reach out if you have any questions.</p>
                    <div className="mt-10 flex items-center justify-center gap-x-6">
                        <a
                            href="/"
                            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gold-600 hover:bg-gold-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gold-500 disabled:opacity-50"
                        >
                            Home
                        </a>
                        <a href={props.serviceReturnPage} className="text-sm font-semibold text-gray-900">
                            Return to {props.service} <span aria-hidden="true">&rarr;</span>
                        </a>
                    </div>
                </div>
            </main>
            <Footer/>
        </>
    )
}
