import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import ApiConfig from "../common/ApiConfig";
import {BaseApi} from "../common/BaseApi";
import {ApiResponse} from "../common/ApiResponse";


export class AxiosApi implements BaseApi {
    private config: ApiConfig;

    public constructor(config: ApiConfig) {
        this.config = config;
    }

    private _getBaseUrl(): string {
        return this.config.baseUrl;
    }

    private _getAxiosConfig(): AxiosRequestConfig {
        // axios will always lowercase the headers: https://github.com/axios/axios/issues/413
        const headers: any = {
            'x-ptech-client': `${this.config.platform}-${this.config.version}`,
        };
        if (this.config.apiKey) {
            headers['x-api-key'] = `${this.config.apiKey}`;
        }
        if (this.config.token) {
            headers['Authorization'] = `Basic ${this.config.token}`;
        }
        if (this.config.source) {
            headers['x-ptech-source'] = this.config.source;
        }
        if (this.config.source) {
            headers['x-ptech-sources'] = this.config.sources;
        }
        return {
            headers: headers,
        };
    }

    public put<T = any>(url: string, data?: any): Promise<AxiosResponse<T>> {
        return axios.put(
            `${this._getBaseUrl()}${url}`,
            data,
            this._getAxiosConfig(),
        );
    }

    public post<T = any>(url: string, data?: any): Promise<ApiResponse<T>> {
        return axios.post(
            `${this._getBaseUrl()}${url}`,
            data,
            this._getAxiosConfig(),
        );
    }

    public get<T = any>(url: string): Promise<AxiosResponse<T>> {
        return axios.get(`${this._getBaseUrl()}${url}`, this._getAxiosConfig());
    }

    public head<T = any>(url: string): Promise<AxiosResponse<T>> {
        return axios.head(`${this._getBaseUrl()}${url}`, this._getAxiosConfig());
    }
}
