import {Route, Routes, useLocation} from "react-router-dom";
import LandingPage from "./LandingPage";
import ContactUsPage from "./ContactUsPage";
import AnotherPage from "./AnotherPage";
import {Eula} from "./Eula";
import * as React from "react";
import {useContext, useEffect} from "react";
import {EnvironmentContext} from "./common/EnvironmentProvider";
import AccountConnectedSuccessPage from "./authorization/AccountConnectedSuccessPage";
import AccountAuthorizationPage from "./authorization/AccountAuthorizationPage";
import AccountConnectionFailurePage from "./authorization/AccountConnectionFailurePage";
import MyCaseAuthorizationPage from "./authorization/mycase/MyCaseAuthorizationPage";
import MyCaseAccountConnectedSuccessPage from "./authorization/mycase/MyCaseAccountConnectedSuccessPage";
import MyCaseAccountConnectionFailurePage from "./authorization/mycase/MyCaseAccountConnectionFailurePage";
import LawmaticsAuthorizationPage from "./authorization/lawmatics/LawmaticsAuthorizationPage";
import LawmaticsAccountConnectedSuccessPage from "./authorization/lawmatics/LawmaticsAccountConnectedSuccessPage";
import LawmaticsAccountConnectionFailurePage from "./authorization/lawmatics/LawmaticsAccountConnectionFailurePage";
import GoogleDriveAccountConnectedSuccessPage from "./authorization/drive/GoogleDriveAccountConnectedSuccessPage";
import GoogleDriveAuthorizationPage from "./authorization/drive/GoogleDriveAuthorizationPage";
import GoogleDriveAccountConnectionFailurePage from "./authorization/drive/GoogleDriveAccountConnectionFailurePage";

export default function RouteContainer() {
    const env = useContext(EnvironmentContext);
    const location = useLocation();
    useEffect(() => {
        env.analytics.setCurrentScreen(location.pathname)
    }, [location, env]);

    return (<Routes>
        <Route path="/" element={<LandingPage/>}/>
        <Route path="/contact" element={<ContactUsPage/>}/>
        <Route path="/mycase/authorize" element={<MyCaseAuthorizationPage
            service="MyCase"
        />}/>
        <Route path="/mycase/authorize/success" element={<MyCaseAccountConnectedSuccessPage
            service="MyCase"
            serviceReturnPage="https://www.mycase.com/login/"
        />}/>
        <Route path="/mycase/authorize/failure" element={<MyCaseAccountConnectionFailurePage
            service="MyCase"
        />}/>
        <Route path="/lawmatics/authorize" element={<LawmaticsAuthorizationPage
            service="Lawmatics"
        />}/>
        <Route path="/lawmatics/authorize/success" element={<LawmaticsAccountConnectedSuccessPage
            service="Lawmatics"
            serviceReturnPage="https://app.lawmatics.com/login"
        />}/>
        <Route path="/lawmatics/authorize/failure" element={<LawmaticsAccountConnectionFailurePage
            service="Lawmatics"
        />}/>
        <Route path="/drive/authorize" element={<GoogleDriveAuthorizationPage
            service="Drive"
        />}/>
        <Route path="/drive/authorize/success" element={<GoogleDriveAccountConnectedSuccessPage
            service="Drive"
            serviceReturnPage="https://drive.google.com/drive/home"
        />}/>
        <Route path="/drive/authorize/failure" element={<GoogleDriveAccountConnectionFailurePage
            service="Drive"
        />}/>
        <Route path="/clio/authorize/success" element={<AccountConnectedSuccessPage
            service="Clio"
            serviceReturnPage="https://app.clio.com"
            serviceLogoPage="https://salesloft-tracking.clio.com/t/11541/c/6239c93f-9566-476a-a49d-4af01f1499ba/NB2HI4DTHIXS653XO4XGG3DJN4XGG33NF5YGC4TUNZSXE43INFYHGL3DMVZHI2LGNFSWILLDN5XHG5LMORQW45DTF47XGYTSMM6TCLLPJV4TE6C7NVJFCLL2KJVE243QNRZFCRLHEUZUIJJTIQSTENCTIQ4DGRSGGBVVUOCLNU2TM6SUO5CF66DKIESTGRBFGNCA====/www-clio-com-partnerships-certified-consultants"
        />}/>
        <Route path="/clio/authorize" element={<AccountAuthorizationPage
            service="Clio"
            serviceReturnPage="https://app.clio.com"
            serviceLogoPage="https://salesloft-tracking.clio.com/t/11541/c/6239c93f-9566-476a-a49d-4af01f1499ba/NB2HI4DTHIXS653XO4XGG3DJN4XGG33NF5YGC4TUNZSXE43INFYHGL3DMVZHI2LGNFSWILLDN5XHG5LMORQW45DTF47XGYTSMM6TCLLPJV4TE6C7NVJFCLL2KJVE243QNRZFCRLHEUZUIJJTIQSTENCTIQ4DGRSGGBVVUOCLNU2TM6SUO5CF66DKIESTGRBFGNCA====/www-clio-com-partnerships-certified-consultants"
        />}/>
        <Route path="/clio/authorize/failure" element={<AccountConnectionFailurePage
            service="Clio"
            serviceReturnPage="https://app.clio.com"
            serviceLogoPage="https://salesloft-tracking.clio.com/t/11541/c/6239c93f-9566-476a-a49d-4af01f1499ba/NB2HI4DTHIXS653XO4XGG3DJN4XGG33NF5YGC4TUNZSXE43INFYHGL3DMVZHI2LGNFSWILLDN5XHG5LMORQW45DTF47XGYTSMM6TCLLPJV4TE6C7NVJFCLL2KJVE243QNRZFCRLHEUZUIJJTIQSTENCTIQ4DGRSGGBVVUOCLNU2TM6SUO5CF66DKIESTGRBFGNCA====/www-clio-com-partnerships-certified-consultants"
        />}/>
        <Route path="another" element={<AnotherPage/>}/>
        <Route path="eula" element={<Eula/>}/>
    </Routes>)

}
