import {BaseApi} from "../common/BaseApi";
import {ApiResponse} from "../common/ApiResponse";
import {Database} from "@firebase/database";
import {getDatabase, push, ref} from "firebase/database";

export class FirebaseApi implements BaseApi {
    private readonly database: Database;

    constructor() {
        this.database = getDatabase();
    }

    get<T = any>(url: string): Promise<ApiResponse<T>> {
        throw new Error("GET request is todo!")
    }

    head<T = any>(url: string): Promise<ApiResponse<T>> {
        throw new Error("HEAD request is todo!")
    }

    post<T = any>(url: string, data?: any): Promise<ApiResponse<T>> {
        let dbRef = ref(this.database, url)
        return push(dbRef, data)
            .then(result => {
                return {
                    data: result,
                    status: 200,
                    statusText: "ok",
                } as ApiResponse
            })
    }

    put<T = any>(url: string, data?: any): Promise<ApiResponse<T>> {
        throw new Error("PUT request is todo!")
    }

}