import React, {useEffect, useMemo} from 'react'
import {useSearchParams} from "react-router-dom";
import {ReactComponent as LogoSvg} from '../../assets/lawmatics-logo.svg';
import {ExternalLinkIcon} from "@heroicons/react/solid";
import {useCookies} from "react-cookie";
import Footer from "../../Footer";
import NavBar from "../../NavBar";

type Props = {
    service: string
};

export default function LawmaticsAuthorizationPage(props: Props) {
    const LAWMATICS_CLIENT_ID = 'fzBnP0VLPNgHQ7lT_4FAZTrfpIE7XT2J-nJyAKcSvmk';
    const [searchParams, setSearchParams] = useSearchParams();
    const [cookies, setCookie, removeCookie] = useCookies(['puretech-auth-retry-link', 'puretech-auth-hostname'])

    useEffect(() => {
        removeCookie("puretech-auth-retry-link", {
            path: '/',
            sameSite: "strict",
        })
    }, []);


    console.log(process.env)
    const navigateToClioAuthorization = () => {
        setCookie('puretech-auth-hostname', window.location.hostname, {
            path: '/',
            sameSite: "lax",
            domain: ".amazonaws.com"
        });
        const authorizeEndpoint = "https://app.lawmatics.com/oauth/authorize";
        const callbackUrl = `${process.env.REACT_APP_AUTHORIZE_API}/callback/lawmatics`;
        const url = authorizeEndpoint + `?response_type=code&client_id=${LAWMATICS_CLIENT_ID}&redirect_uri=` + callbackUrl;
        setCookie('puretech-auth-retry-link', url, {
            path: '/',
            sameSite: "strict",
        });
        // cookies.update()
        window.location.href = url
    }

    return (
        <>
            <NavBar/>
            <main className="place-items-center bg-white px-6 py-20 sm:py-28 lg:px-8">
                <div className="justify-center flex mb-10">
                    <LogoSvg className="max-h-36"/>
                </div>
                <div className="text-center">
                    <h1 className="mt-6 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Connect
                        your {props.service} Account</h1>
                    <p className="mt-6 text-base  text-gray-600">Please use the link below to grant the PureTech app
                        access to your {props.service} Account.</p>
                    <div className="mt-10 inline-flex rounded-md shadow">
                        <a
                            href="#"
                            onClick={navigateToClioAuthorization}
                            className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gold-600 hover:bg-gold-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gold-500  cursor-pointer"
                        >
                            Connect
                            <ExternalLinkIcon className="-mr-1 ml-3 h-5 w-5 text-white-400" aria-hidden="true"/>
                        </a>
                    </div>
                </div>
            </main>
            <Footer/>
        </>
    )
}
