import React from 'react'
import NavBar from "./NavBar";
import Footer from "./Footer";
import {FeatureSectionWithGrid} from "./FeatureSectionWithGrid";
import {CtaSection} from "./CtaSection";
import {HeroSection} from "./HeroSection";


export default function LandingPage() {
    return (
        <div className="bg-white">
            <div className="relative overflow-hidden">
                <NavBar/>
                <main>
                    <HeroSection/>
                    {/*<FeatureSectionWithScreenShot/>*/}
                    <FeatureSectionWithGrid/>
                    {/*<TestimonialSection/>*/}
                    {/*<BlogSection/>*/}
                    <CtaSection/>
                </main>
                <Footer/>
            </div>
        </div>
    )
}
