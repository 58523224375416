// @flow
import * as React from 'react';
import NavBar from "./NavBar";

type Props = {};

export function Eula(props: Props) {
    return (
        <div>
            <NavBar/>
            <div className="mx-auto max-w-7xl lg:px-8 pb-16 pt-16">
                <h2 className="text-4xl font-extrabold">End-User License Agreement (EULA) of PureTech Cloud</h2>
                <p className="mt-4">This End-User License Agreement
                    (“EULA”) is a legal agreement between you and PureTech. Our EULA was created for all PureTech
                    services, applications, web applications, and sites, including PureTech Cloud and
                    www.PureTech.dev.</p>
                <p className="mt-8">This EULA agreement governs your
                    acquisition and use of our PureTech Cloud software (“Software”) directly from PureTech or
                    indirectly
                    through a PureTech authorized reseller or distributor (a “Reseller”). </p>
                <p className="mt-8">Please read this EULA agreement
                    carefully before completing the installation process and using the PureTech Cloud software. It
                    provides a license to use the PureTech Cloud software and contains warranty information and
                    liability disclaimers.</p>
                <p className="mt-8">If you register for a free trial of the
                    PureTech Cloud software, this EULA agreement will also govern that trial. By clicking “accept”
                    or
                    installing and/or using the PureTech Cloud software, you are confirming your acceptance of the
                    Software and agreeing to become bound by the terms of this EULA agreement.</p>
                <p className="mt-8">If you are entering into this EULA
                    agreement on behalf of a company or other legal entity, you represent that you have the
                    authority to bind such entity and its affiliates to these terms and conditions. If you do not
                    have such authority or if you do not agree with the terms and conditions of this EULA agreement,
                    do not install or use the Software, and you must not accept this EULA agreement.</p>
                <p className="mt-8">This EULA agreement shall apply only to
                    the Software supplied by PureTech herewith regardless of whether other software is referred to
                    or
                    described herein. The terms also apply to any PureTech updates, supplements, Internet-based
                    services, and support services for the Software, unless other terms accompany those items on
                    delivery. If so, those terms apply.</p>


                <h3 className="text-2xl font-extrabold mt-8">License Grant</h3>
                <p className="mt-4">PureTech hereby grants you a personal,
                    non-transferable, non-exclusive licence to use the PureTech Cloud software on your devices in
                    accordance with the terms of this EULA agreement.</p>
                <p className="mt-8">You are permitted to load the PureTech Cloud software (for example a PC, laptop,
                    mobile or
                    tablet) under your control. You are
                    responsible for ensuring your device meets the minimum requirements of the PureTech Cloud
                    software.</p>
                <p className="mt-8">You are not permitted to:</p>
                <ul className="list-disc ml-8">
                    <li>Edit, alter, modify, adapt, translate or otherwise change the whole or any part of the
                        Software nor permit the whole or any part of the Software to be combined with or become
                        incorporated in any other software, nor decompile, disassemble or reverse engineer the
                        Software or attempt to do any such things
                    </li>
                    <li>Reproduce, copy, distribute, resell or otherwise use the Software for any commercial
                        purpose
                    </li>
                    <li>Allow any third party to use the Software on behalf of or for the benefit of any third
                        party
                    </li>
                    <li>Use the Software in any way which breaches any applicable local, national or international
                        law
                    </li>
                    <li>use the Software for any purpose that PureTech considers is a breach of this EULA agreement
                    </li>
                </ul>

                <h3 className="text-2xl font-extrabold mt-8">Intellectual Property and Ownership</h3>
                <p className="mt-4">PureTech shall at all times retain
                    ownership of the Software as originally downloaded by you and all subsequent downloads of the
                    Software by you. The Software (and the copyright, and other intellectual property rights of
                    whatever nature in the Software, including any modifications made thereto) are and shall remain
                    the property of PureTech.</p>
                <p className="mt-8">PureTech reserves the right to grant
                    licences to use the Software to third parties.</p>

                <h3 className="text-2xl font-extrabold mt-8">Termination</h3>
                <p className="mt-8">This EULA agreement is effective from
                    the date you first use the Software and shall continue until terminated. You may terminate it at
                    any time upon written notice to PureTech.</p>
                <p className="mt-8">It will also terminate immediately if
                    you fail to comply with any term of this EULA agreement. Upon such termination, the licenses
                    granted by this EULA agreement will immediately terminate and you agree to stop all access and
                    use of the Software. The provisions that by their nature continue and survive will survive any
                    termination of this EULA agreement.</p>
                <h3 className="text-2xl font-extrabold mt-8">Governing Law</h3>
                <p className="mt-4">This EULA agreement, and any dispute
                    arising out of or in connection with this EULA agreement, shall be governed by and construed in
                    accordance with the laws of the United States.</p>
            </div>
        </div>
    );
};