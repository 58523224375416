const grid = JSON.stringify([
    {
        name: 'Custom Software Development',
        description: 'Custom software built from the ground up the way you like it.',
        icon: "TerminalIcon",
    },
    {
        name: 'Cross-Platform Integrations',
        description: 'Integrate applications seamlessly across multiple platforms and ecosystems.',
        icon: "LinkIcon",
    },
    {
        name: 'Mobile Applications',
        description: 'Create mobile applications for use by your team on the go or by your customers.',
        icon: "DeviceMobileIcon",
    },
    {
        name: 'Automation',
        description: 'Scale your business by automating complex or potentially error-prone processes.',
        icon: "SparklesIcon",
    },
    {
        name: 'Legacy System & Software Modernization',
        description:
            'Extend or replace your existing systems in safe and efficient manner.',
        icon: "ServerIcon",
    },
    {
        name: 'Cloud Services',
        description: 'Run your applications anywhere at anytime in the cloud.',
        icon: "CloudIcon",
    },
]);

//Photo by <a href="https://unsplash.com/@anniespratt?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Annie Spratt</a> on <a href="https://unsplash.com/s/photos/software-development?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
//Photo by <a href="https://unsplash.com/@austindistel?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Austin Distel</a> on <a href="https://unsplash.com/?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
//
const defaults = {
    "heroSection_HeaderLine1": "Technology built",
    "heroSection_HeaderLine2": "the way you like it",
    "heroSection_HeaderSubtitle": "Your business is special. The technology you use can be built to support your business's unique needs.",
    // "heroSection_ImageUrl": "https://tailwindui.com/img/component-images/cloud-illustration-teal-cyan.svg",

    "ctaSection_HeaderLine": "We're here to help",
    "ctaSection_HeaderAccent": "",
    "ctaSection_HeaderSubtitle": "Our engineers at PureTech bring together technical expertise and soft skills to ensure that your project is done right, from the start.",
    "ctaSection_ButtonText": "Contact Us Today",
    // "ctaSection_ImageUrl": "https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=60&sat=-100",

    "featuredGrid_HeaderLine": "Everything built specifically for your business",
    "featuredGrid_HeaderSubtitle": "PureTech software solutions cover a complete range of tools, technologies, and languages, so you can accomplish exactly what you need, without limitations.",
    "featuredGrid_AccentLine": "Custom Technology Solutions",
    "featuredGrid_grid": grid,
}

export default defaults;