// @flow
import * as React from 'react';
import {useContext} from 'react';
import {ExternalLinkIcon} from "@heroicons/react/solid";
import {useFirebaseConfig} from "./hooks/useFirebaseConfig";
import hereToHelpImage from "./assets/here-to-help-md.jpg"
import {useNavigate} from "react-router-dom";
import {EnvironmentContext} from "./common/EnvironmentProvider";

type Props = {};

export function CtaSection(props: Props) {
    const env = useContext(EnvironmentContext);
    const navigate = useNavigate();
    const {
        ctaSection_HeaderLine,
        ctaSection_HeaderSubtitle,
        ctaSection_HeaderAccent,
        ctaSection_ImageUrl,
        ctaSection_ButtonText,
    } = useFirebaseConfig();

    const getHeader = () => {
        return ctaSection_HeaderLine;
    }
    const getHeaderSubtitle = () => {
        return ctaSection_HeaderSubtitle;
    }
    const getHeaderAccent = () => {
        return ctaSection_HeaderAccent;
    }
    const getImageUrl = () => {
        let imageUrl = hereToHelpImage
        if (ctaSection_ImageUrl) {
            const firebaseOverride = ctaSection_ImageUrl.toString()
            if (firebaseOverride.length > 0) {
                imageUrl = firebaseOverride
            }
        }
        return imageUrl;
    }
    const getButtonText = () => {
        return ctaSection_ButtonText;
    }
    const navigateToContactForm = () => {
        env.analytics.logEvent('Cta:ContactUs')
        navigate({
            pathname: "contact"
        });
    }


    return (
        <div className="relative bg-gray-900">
            <div className="relative h-56 bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
                <img
                    className="w-full h-full object-cover"
                    src={getImageUrl()}
                    alt=""
                />
                <div
                    aria-hidden="true"
                    className="absolute inset-0 bg-gradient-to-r from-yellow-300 to-gold-500 mix-blend-multiply"
                />
            </div>
            <div
                className="relative mx-auto max-w-md px-4 py-12 sm:max-w-7xl sm:px-6 sm:py-20 md:py-28 lg:px-8 lg:py-32">
                <div className="md:ml-auto md:w-1/2 md:pl-10">
                    <h2 className="text-base font-semibold uppercase tracking-wider text-gray-300">
                        {getHeaderAccent()}
                    </h2>
                    <p className="mt-2 text-white text-3xl font-extrabold tracking-tight sm:text-4xl">{getHeader()}</p>
                    <p className="mt-3 text-lg text-gray-300">
                        {getHeaderSubtitle()}
                    </p>
                    <div className="mt-8">
                        <div className="inline-flex rounded-md shadow">
                            <a
                                href="#"
                                onClick={navigateToContactForm}
                                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50 cursor-pointer"
                            >
                                {getButtonText()}
                                <ExternalLinkIcon className="-mr-1 ml-3 h-5 w-5 text-gray-400" aria-hidden="true"/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};