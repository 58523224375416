import {ContactForm} from "../ContactUsPage";
import {BaseApi} from "../apis/common/BaseApi";


export class LeadService {
    private readonly api: BaseApi

    constructor(api: BaseApi) {
        this.api = api;
    }

    postContactForm = (data: ContactForm, onSuccess: () => void, onFailure: () => void) => {
        data.submitted = new Date().toLocaleDateString();
        this.api.post('/leads', data)
            .then(onSuccess)
            .catch((error) => {
                console.log("Failure! ", error);
                onFailure();
            });
    }

    postNewLeadLinkView = (leadSourceId: string, pastLeadSources: string, onSuccess: () => void) => {
        this.api.post('/lead-links/' + leadSourceId + "/page-view", {
            "previousLeadLinks": pastLeadSources
        })
            .then(onSuccess)
            .catch((error) => console.error("Lead Link Failed to Send", error))
    }
}
