// @flow
import * as React from 'react';
import {useFirebaseConfig} from "./hooks/useFirebaseConfig";
import {HeroIconByName} from "./HeroIconByName";

type Props = {};

type FeaturedGridItem = {
    name: string;
    description: string;
    icon: string;
}

export function FeatureSectionWithGrid(props: Props) {
    const {
        featuredGrid_HeaderLine,
        featuredGrid_HeaderSubtitle,
        featuredGrid_AccentLine,
        featuredGrid_grid,
    } = useFirebaseConfig();

    const getFeaturedGrid = () => {
        const grid: FeaturedGridItem[] = JSON.parse(featuredGrid_grid.toString());
        return grid;
    }
    const getAccentLine = () => {
        return featuredGrid_AccentLine;
    }
    const getHeaderLine = () => {
        return featuredGrid_HeaderLine;
    }
    const getSubtitle = () => {
        return featuredGrid_HeaderSubtitle;
    }


    return (
        <div className="relative bg-white py-16 sm:py-24 lg:py-32">
            <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
                <h2 className="text-base font-semibold tracking-wider text-gold-600 uppercase">{getAccentLine()}</h2>
                <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
                    {getHeaderLine()}
                </p>
                <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
                    {getSubtitle()}
                </p>
                <div className="mt-12">
                    <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                        {getFeaturedGrid().map((feature) => (
                            <div key={feature.name} className="pt-6">
                                <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                                    <div className="-mt-6">
                                        <div>
                            <span
                                className="inline-flex items-center justify-center p-3 bg-gradient-to-r from-amber-500 to-gold-600 rounded-md shadow-lg">
                                <HeroIconByName name={feature.icon} className="h-6 w-6 text-white"/>
                            </span>
                                        </div>
                                        <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">{feature.name}</h3>
                                        <p className="mt-5 text-base text-gray-500">{feature.description}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};