// @flow
import * as React from 'react';
import {useEffect, useMemo, useState} from 'react';
import {BrowserRouter} from "react-router-dom";
import FirebaseApp from "./firebase/FirebaseApp";
import FirebaseConfigProvider from "./firebase/FirebaseConfigProvider";
import defaultConfigs from './firebase/Defaults';
import EnvironmentProvider, {EnvironmentContext} from "./common/EnvironmentProvider";
import {getAnalytics} from "firebase/analytics";
import {AnalyticsService} from "./service/AnalyticsService";
import RouteContainer from "./RouteContainer";
import {LeadService} from "./service/LeadService";

type Props = {};

// TODO: disable this entirely once the images are purchased?
const FIREBASE_CONFIG_ENABLED = false;

export function App(props: Props) {
    const analyticsService = useMemo(() => {
        let analytics = null;
        try {
            analytics = getAnalytics(FirebaseApp())
        } catch (e) {
            console.error("An error occurred on app start up.", e)
        }
        return new AnalyticsService(analytics);
    }, []);
    const [env] = useState(() => new EnvironmentProvider(analyticsService));
    useEffect(() => {
        const ls = localStorage.getItem("ls");
        if (ls !== null) {
            const lastLeadSourceListSent = localStorage.getItem("lsl") || '';
            if (lastLeadSourceListSent.split(",").indexOf(ls) === -1) {
                new LeadService(env.api).postNewLeadLinkView(ls, lastLeadSourceListSent, () => {
                    console.debug("Lead Link Sent");
                    localStorage.setItem("lsl", lastLeadSourceListSent + "," + ls);
                });
            }
        }
    }, [env.api])

    const DEV_NODE_ENV = `${process.env.NODE_ENV}` === 'development'
    const PROD_CONFIG = `${process.env.REACT_APP_PROD}` === 'true'


    return (
        <div className="h-full min-h-full flex flex-col">
            <EnvironmentContext.Provider value={env}>
                <FirebaseConfigProvider defaults={defaultConfigs} enabled={FIREBASE_CONFIG_ENABLED}>
                    {{
                        loading: null,
                        content: (<BrowserRouter>
                            {DEV_NODE_ENV ? (
                                <div className="text-center text-red-900 font-bold"> DEV NODE ENV </div>) : ''}
                            {DEV_NODE_ENV && PROD_CONFIG ? (
                                <div className="text-center text-cyan-300 font-bold bg-fuchsia-600"> PROD
                                    CONFIG </div>) : ''}
                            <div id="route-container">
                                <RouteContainer/>
                            </div>
                        </BrowserRouter>)
                    }}
                </FirebaseConfigProvider>
            </EnvironmentContext.Provider>
        </div>
    );
};