import {initializeApp} from "firebase/app"
import {FirebaseOptions} from "@firebase/app";

let config: FirebaseOptions;
if (`${process.env.REACT_APP_PROD}` === 'true') {
    config = {
        apiKey: "AIzaSyAbNXpC4YfO8mEz5pyCU9PEdD78niR1FH4",
        authDomain: "puretech-6d0bf.firebaseapp.com",
        databaseURL: "https://puretech-6d0bf-default-rtdb.firebaseio.com",
        projectId: "puretech-6d0bf",
        storageBucket: "puretech-6d0bf.appspot.com",
        messagingSenderId: "758452778416",
        appId: "1:758452778416:web:146867ef0e2e809e7d1275",
        measurementId: "G-F0J2HEC94S"
    };
} else {
    config = {
        apiKey: "AIzaSyAbNXpC4YfO8mEz5pyCU9PEdD78niR1FH4",
        authDomain: "puretech-6d0bf.firebaseapp.com",
        databaseURL: "https://puretech-6d0bf-default-rtdb.firebaseio.com",
        projectId: "puretech-6d0bf",
        storageBucket: "puretech-6d0bf.appspot.com",
        messagingSenderId: "758452778416",
        appId: "1:758452778416:web:392796cbd049d3d87d1275",
        measurementId: "G-P6EL6ENS1Q"
    };
}
const app = initializeApp(config);
export default function () {
    console.log("Returning Firebase App")
    return app;
};
