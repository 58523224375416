import React, {useEffect, useMemo} from 'react'
import {useSearchParams} from "react-router-dom";
import NavBar from "../NavBar";
import Footer from "../Footer";
// import {ReactComponent as LogoSvg} from '../assets/clio-logo.svg';
import ClioLogo from '../assets/clio-affiliate-logo.png';
import {ExternalLinkIcon} from "@heroicons/react/solid";
import {Buffer} from "buffer";
import {nanoid} from "nanoid";
import {useCookies} from "react-cookie";
import {MailIcon} from "@heroicons/react/outline";

type Props = {
    service: string
    serviceReturnPage: string
    serviceLogoPage: string
};


export default function AccountAuthorizationPage(props: Props) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [cookies, setCookie, removeCookie] = useCookies(['puretech-auth-retry-link', 'puretech-auth-hostname'])
    const clientId = useMemo(() => {
        return searchParams.get("id")
    }, [searchParams]);

    useEffect(() => {
        removeCookie("puretech-auth-retry-link", {
            path: '/',
            sameSite: "strict",
        })
    }, []);

    const stateArg = useMemo(() => {
        if (clientId == null) {
            return '';
        }
        let clientEncodedId = clientId;
        try {
            const randomId = nanoid(12)
            clientEncodedId = Buffer.alloc(8, randomId).toString('base64') + Buffer.from(clientId).toString('base64')
        } catch (e) {
            console.warn("Unable to encode state arg.", e);
        }
        return clientEncodedId;
    }, [clientId]);

    console.log(process.env)
    const navigateToClioAuthorization = () => {
        setCookie('puretech-auth-hostname', window.location.hostname, {
            path: '/',
            sameSite: "lax",
            domain: ".amazonaws.com"
        });
        const authorizeEndpoint = "https://app.clio.com/oauth/authorize";
        const callbackUrl = `${process.env.REACT_APP_AUTHORIZE_API}/callback/clio`;
        const url = authorizeEndpoint + "?response_type=code&client_id=" + clientId + "&redirect_uri=" + callbackUrl + "&state=" + stateArg;
        setCookie('puretech-auth-retry-link', url, {
            path: '/',
            sameSite: "strict",
        });
        // cookies.update()
        window.location.href = url
    }

    return (
        <>
            <NavBar/>
            <main className="place-items-center bg-white px-6 py-20 sm:py-28 lg:px-8">
                <div className="justify-center flex mb-10">
                    <a href={props.serviceLogoPage} target="_blank">
                        <img src={ClioLogo} alt={props.service + " Logo"} className="max-h-36"/>
                    </a>
                </div>
                <div className="text-center">
                    {stateArg === '' ? (<React.Fragment>
                        <h1 className="mt-6 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Oops!</h1>
                        <p className="mt-6 text-base  text-gray-600"> It looks like something is not quite right.</p>
                        <p className="mt-2 text-base  text-gray-600">The link you are using is broken. Please contact
                            support get a new one.</p>
                        <div className="mt-10 flex items-center justify-center gap-x-6">
                            <a
                                href={"mailto:info@puretech.dev?subject=Help Connecting " + props.service + " Account"}
                                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gold-600 hover:bg-gold-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gold-500  cursor-pointer"
                            >
                                Contact Support
                                <MailIcon className="-mr-1 ml-3 h-5 w-5 text-white-400" aria-hidden="true"/>
                            </a>
                        </div>
                    </React.Fragment>) : (<React.Fragment>
                        <h1 className="mt-6 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Connect
                            your {props.service} Account</h1>
                        <p className="mt-6 text-base  text-gray-600">Please use the link below to grant the PureTech app
                            access to your Clio Account.</p>
                        <div className="mt-10 inline-flex rounded-md shadow">
                            <a
                                href="#"
                                onClick={navigateToClioAuthorization}
                                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gold-600 hover:bg-gold-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gold-500  cursor-pointer"
                            >
                                Connect
                                <ExternalLinkIcon className="-mr-1 ml-3 h-5 w-5 text-white-400" aria-hidden="true"/>
                            </a>
                        </div>
                    </React.Fragment>)}

                </div>
            </main>
            <Footer/>
        </>
    )
}
