import React, {ReactNode} from 'react';
import {getRemoteConfig, fetchAndActivate, getAll} from "firebase/remote-config";
import FirebaseConfigContext from "./FirebaseConfigContext";

const remoteConfig = getRemoteConfig()
remoteConfig.settings.minimumFetchIntervalMillis = parseInt(process.env.REACT_APP_FIREBASE_REFRESH || '600000');

type Props = {
    enabled: boolean
    defaults: {
        [key: string]: string | number | boolean;
    };
    children: {
        loading: ReactNode | null,
        content: ReactNode
    };
};


const FirebaseConfigProvider = (props: Props) => {
    const [configs, setConfigs] = React.useState(props.defaults);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        remoteConfig.defaultConfig = props.defaults;
        if (props.enabled) {
            fetchAndActivate(remoteConfig)
                .then((activated) => {
                    if (!activated) console.log('not activated');
                    return getAll(remoteConfig);
                })
                .then(remoteFlags => {
                    const newConfigs = {
                        ...configs,
                    };
                    for (const [key, config] of Object.entries(remoteFlags)) {
                        newConfigs[key] = config.asString();
                    }
                    setConfigs(newConfigs);
                })
                .catch((err) => {
                    console.error("Failed to load remote config", err)
                })
                .finally(() => {
                    setLoading(false)
                })
        } else {
            setLoading(false)
        }
    }, []);

    return (
        <FirebaseConfigContext.Provider value={configs}>
            {loading && props.children.loading != null ? props.children.loading : props.children.content}
        </FirebaseConfigContext.Provider>
    );
};

export default FirebaseConfigProvider;